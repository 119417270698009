<template>
  <div>
    <div class="flex justify-between">
      <p class="font-headers font-bold text-xl text-grey-dark-2 ml-1">
        Organizational Benchmarks
      </p>
      <Button
        text="Save"
        type="primary"
        size="medium"
        :disabled="disableSave"
        @click.native="save"
        class="px-3 ml-auto"
      />
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <p class="font-headers font-bold text-mdl text-grey-light mb-2">
        Set Organizational Benchmarks for existing Assessment
      </p>
      <div class="px-2">
        <p class="text-mdh text-grey-dark-1 max-w-3/5 mb-3">
          Benchmarks need to be set for the existing competencies specific to each
          assessment. All competencies need to be marked with benchmarks. You are
          able to enter a benchmark value between 0 and {{ this.maxScore }}.
        </p>
      </div>
      <TargetsTable :handler="handler" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import TargetsTable from "../components/TargetsTable";

export default {
  name: "OrganizationalTargets",
  components: { TargetsTable },
  data: () => ({ targets: [] }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
      maxScore: (state) => state.products.scores.length - 1,
    }),
    disableSave() {
      return false;
    },
  },
  methods: {
    ...mapActions({
      updateOrgTargets: "products/updateOrgTargets",
    }),
    handler(list) {
      this.targets = list;
    },
    save() {
      let targets = this.targets.map((item) => {
        item.value = parseFloat(item.value);
        return item;
      });
      this.updateOrgTargets({
        id: this.productDetails.id,
        payload: { surveyId: this.productDetails.id, targets },
      }).then(() => this.$router.push("dashboard"));
    },
  },
};
</script>
